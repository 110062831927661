import React, { Component } from 'react';
import FilterListItem from './FilterListItem';

class FilterList extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.filterItems = [];
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  filterFunctionUpdated = (e) => {
    this.props.filterFunctionUpdated(e);
  }

  handleFilterSelection = (e) => {
    this.props.handleFilterSelection(e);
  }

  render() {
    this.filterItems = this.props.filter.subFilters.map((subFilter, i) => {
      let subFilterValue = subFilter.value.trim();

      let isSelected = null; // reset
      if (this.props.activeFiltersMap[this.props.filterName] && this.props.activeFiltersMap[this.props.filterName][subFilterValue]) {
        isSelected = 'selected';
      }

      const searchValue = '.';
      let periodIndex = subFilterValue.indexOf(searchValue);
      if (periodIndex > -1) {
        subFilterValue = subFilterValue.replace(/\./g, '%2E');
      }
      let subFilterLabel = subFilter.label.trim();
      
      return (
        <FilterListItem
          key={i}
          itemIndex={i}
          subFilter={subFilter} 
          isSelected={isSelected} 
          handleFilterSelection={this.handleFilterSelection}
          filterName={this.props.filterName}
          subFilterValue={subFilterValue}
          subFilterLabel={subFilterLabel}
          showAllFilterListItems={this.props.showAllFilterListItems}
          displayFilterListItems={this.props.displayFilterListItems}
        />
      );
    });

    return (
      <ul className="filter-content" aria-hidden={this.props.isFilterHidden}>
        {this.filterItems}
      </ul>
    );
  }
}

export default FilterList;
