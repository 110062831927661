class marketplaceNormalizer {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;
		let searchResults = {
			totalResults: rawResults.count,
			offset: rawResults.offset,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}

		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = rawResult.doc;
			card.link = result.displayurl || '';
			card.title = result.metaFields.pageTitle || '';
			card.icon = result.metaFields['og:image'] || '';
			card.pricingModel = result.metaFields.pricingModel || '';
			card.vendor = result.metaFields.vendorName || '';
			card.description = result.description || '';
			card.productType = result.metaFields.productType || '';
			card.rating = result.metaFields.ratings || '';
			card.feature = result.metaFields.featured;
			return card;
		});
		return searchResults;
	}
}

export default marketplaceNormalizer;
