class SearchConfig {
	static omni(props) {
		let config = {};
		let site = 'esri_store_omni';
		if (props.payloadSite != null) {
			site = props.payloadSite;
		}
		// api default payload
		config.alwaysSort = true;
		config.defaultPayload = {
			num: 100,
			client: 'esri_explore',
			start: 0,
			q: '',
			sort: 'metaFields.rank:A',
			site: site,
			format: 'json',
			len: 100,
			offset: 0,
			index: site,
		};

		return config;
	}

	static partners(props, offset = 0, resultsPerPage = 15) {
		let config = {};
		config.pagination = true;
		config.resultsPerPage = resultsPerPage;
		config.sort = true;
		config.sortParameter = 'metaFields.company-sort.keyword:A';

		// api default payload
		config.defaultPayload = {
			lr: 'en',
			num: resultsPerPage,
			client: 'esri_explore',
			start: offset,
			partialfields: 'page-type:partners',
			q: '',
			site: 'esri_all_partners',
			format: 'json',
			len: 100,
			offset: 0,
		};

		return config;
	}

	static careers(props, offset = 0, resultsPerPage = 15) {
		let config = {};
		config.pagination = true;
		config.resultsPerPage = resultsPerPage;
		config.sort = true;
		config.sortParameter = 'metaFields.featured.keyword:D,metaFields.resourceName.keyword:A';

		// api default payload
		config.defaultPayload = {
			lr: 'en',
			num: resultsPerPage,
			client: 'esri_explore',
			start: offset,
			q: '',
			site: 'esri_careers',
			format: 'json',
			len: 100,
			offset: 0,
		};

		return config;
	}

	static marketplace(props, offset = 0, resultsPerPage = 21) {
		let config = {};
		config.pagination = true;
		config.resultsPerPage = resultsPerPage;
		config.sort = true;
		config.sortParameter = 'metaFields.publishedDate.keyword:D';

		// api default payload
		config.defaultPayload = {
			lr: 'en',
			num: resultsPerPage,
			client: 'esri_explore',
			start: offset,
			q: '',
			site: props.searchType,
			format: 'json',
			len: 100,
			offset: 0,
			index: props.searchType,
		};

		return config;
	}

	static distributors(props, offset = 0, resultsPerPage = 10) {
		let config = {};
		config.pagination = true;
		config.resultsPerPage = resultsPerPage;
		config.defaultPayload = {
			num: resultsPerPage,
			client: 'esri_general',
			index: props.distributorSite,
			len: '100',
			start: offset,
			q: '"esri"',
			site: props.distributorSite,
			format: 'json',
			offset: 0,
		};

		return config;
	}

	static press(props, offset = 0, resultsPerPage = 16) {
		let config = {};
		config.pagination = true;
		config.resultsPerPage = resultsPerPage;
		config.sort = true;
		config.sortParameter = 'metaFields.publishDate:D';

		config.defaultPayload = {
			lr: 'en',
			num: resultsPerPage,
			client: 'esri_explore',
			start: offset,
			q: '',
			site: 'esri_press',
			format: 'json',
			len: 100,
			offset: 0,
			index: 'esri_press',
		};

		return config;
	}

	static support(props, offset = 0, resultsPerPage = 15) {
		let language = window.location.pathname.slice(1, 6);
		if (language && language !== 'en-us') {
			language = language + '|en';
		} else {
            language = "en";
        }
		let config = {};
		config.pagination = true;
		config.resultsPerPage = resultsPerPage;

        config.defaultPayload = {
          num: 15,
          client: 'support_all',
          start: 0,
          site: 'support_patches_updates|support_technical_articles|support_technical_papers|doc_arcgis|support_gisdictionary|support_bug_articles|support_change_records|esri_arcgis_blog|community_esri|esri_videos_kaltura',
          format: 'json',
          len: 100,
          lr: language,
          index: 'support_all',
          _source: {
              excludes: ['content', 'meta', 'connectorSpecific'],
          },
      };

		return config;
	}
}
export default SearchConfig;
