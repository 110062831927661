import React, { Component } from 'react';

class FilterListTitle extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {

  }

  toggleFilterExpansion = () => {
    this.props.toggleFilterExpansion();
  }

  chevronToggle = () => {
    if(this.props.isFilterHidden === true) {
      return 'chevron-down';
    }
    return 'chevron-up';
  }

  render() {
    return (
      <label
        htmlFor={this.props.filterName}
        onClick={() => {
          this.toggleFilterExpansion();
        }}
        data-attribute={this.props.isParentSelected}
        aria-hidden={this.props.isFilterHidden}
      >
        {this.props.filterLabel}
        <calcite-icon icon={this.chevronToggle()} aria-hidden="false" scale="s" calcite-hydrated=""></calcite-icon>
      </label>
    );
  }
}

export default FilterListTitle;
