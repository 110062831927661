class distributorsNormalize {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;

		let searchResults = {
			totalResults: rawResults.count,
			offset: rawResults.offset,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}
		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = [];
			if (rawResult.highlight != undefined) {
				result = rawResult.highlight;
			}

			let description = [];
			let desc = [];
			if (result.content != undefined) {
				Object.keys(result.content).map(function (key, index) {
					description.push(result.content[key]);
				});
			}
			desc = description.join('...').replace(/\s\s+/g, ' ');
			card.id = rawResult.id;
			card.description = desc;
			card.title = result.title;

			if (card.title == undefined) {
				card.title = rawResult.doc.title;
			}
			return card;
		});

		return searchResults;
	}
}

export default distributorsNormalize;
