class pressNormalizer {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;
		let searchResults = {
			totalResults: rawResults.count,
			offset: rawResults.offset,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}
		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = rawResult.doc;
			card.title = result.metaFields.bookTitle || '';
			card.image = result.metaFields.image || '';
			card.authors = result.metaFields.authors || '';
			card.url = result.url || '';
			return card;
		});
		return searchResults;
	}
}

export default pressNormalizer;
