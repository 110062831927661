import React, { Component } from 'react';
import Utils from '../../sr-utils';

class SingleBarSearch extends Component {
	constructor(props) {
		super(props);
		this.initQuery = this.props.activeSearchQ;
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		// search animation
		if (window.esri_sha_underlineAnimation) {
			var cuTextInput = document.querySelector('.custom-underline-text-input');
			if (cuTextInput) {
				// //apps/esri-sites/clientlibs/components/js/search-hover-animation.js
				window.esri_sha_underlineAnimation(cuTextInput);
			}
		}
	}

	handleChange(event) {
		this.props.handleSearchChange(event.target.value);
	}

	handleSubmit(event) {
		event.preventDefault();
		this.props.handleSubmit();
	}

	render() {
		let placeholder = this.props.labels.searchInputMessage;
		return (
			<section className="sra-search filterpage column-19">
				<div className="filter-icon avenir-demi font-size-0">Filter</div>

				<div className="simple-search searchresultspage">
					<div className="sra-search-field">
						<form onSubmit={this.handleSubmit}>
							<div className="custom-underline-text-input-container">
								<input
									id="a"
									onChange={this.handleChange}
									type="search"
									placeholder={placeholder}
									className="custom-underline-text-input sra-search-textfield"
									required=""
									value={this.props.initQvalue}
								/>
								<input type="submit" value="" aria-label={`search ${this.props.initQvalue}`} className="sra-submit sra-spy-glass-icon" />
								<div className="custom-underline-text-input-grayline"></div>
								<div className="custom-underline-text-input-blueline"></div>
								<div className="custom-underline-text-input-measure"></div>
							</div>
						</form>
					</div>
				</div>
			</section>
		);
	}
}

export default SingleBarSearch;
