import React, { Component } from 'react';
import FilterList from './FilterList';
import ShowAllToggle from './ShowAllToggle';

class FilterListWrapper extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showAllFilterListItems: false,
    }
    this.displayFilterListItems = 10;
  }

  componentDidMount() {

  }

  filterFunctionUpdated = (e) => {
    this.props.filterFunctionUpdated(e);
  }

  handleFilterSelection = (e) => {
    this.props.handleFilterSelection(e);
  }

  handleDisplayFilterListItems = () => {
    this.setState({showAllFilterListItems: !this.state.showAllFilterListItems});
  }

  displayShowAllToggle = () => {
    if(this.props.filter.subFilters.length >= this.displayFilterListItems) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="filter-list-wrapper">
        <input
          type="text"
          className={this.props.searchClass}
          data-index={this.props.dataIndex}
          id={`filterSearch-${this.props.dataIndex}`}
          placeholder={this.props.searchInputText}
          onKeyUp={this.filterFunctionUpdated}>
        </input>
        <FilterList
          isFilterHidden={this.props.isFilterHidden}
          filterName={this.props.filterName}
          filter={this.props.filter}
          activeFiltersMap={this.props.activeFiltersMap}
          handleFilterSelection={this.handleFilterSelection}
          showAllFilterListItems={this.state.showAllFilterListItems}
          displayFilterListItems={this.displayFilterListItems}
        />
        <div className={`showAllToggle-wrapper ${this.state.showAllFilterListItems ? 'showAllToggle-wrapper--sticky' : 'showAllToggle-wrapper--static'}`}>
          {this.displayShowAllToggle() && 
            <ShowAllToggle 
            isFilterHidden={this.props.isFilterHidden}
            handleDisplayFilterListItems={this.handleDisplayFilterListItems}
          />}
        </div>
      </div>
    );
  }
}

export default FilterListWrapper;
