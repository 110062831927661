import React, { Component } from 'react';
import CalciteCheckbox from '../../../shared/CalciteCheckbox';

class FilterListItem extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.displayFilterListItems = 10;
  }

  componentDidMount() {
  }

  displayFilterListItem = () => {
    if(!this.props.showAllFilterListItems && this.props.itemIndex < this.props.displayFilterListItems) {
      return 'block';
    } else if(!this.props.showAllFilterListItems && this.props.itemIndex >= this.props.displayFilterListItems){
      return 'none';
    } else {
      return 'block';
    }
  }

  handleFilterSelection = (e) => {
    this.props.handleFilterSelection(e);
  }

  render() {
    return (
      <li
        style={{ display: this.displayFilterListItem() }}>
        <calcite-label 
          layout="inline"
          class="filter-link new"
          data-attribute={this.props.isSelected}
          data-field={this.props.filterName}
          data-value={this.props.subFilterValue}
          data-label={this.props.subFilterLabel}
          role="option">
            <CalciteCheckbox onChange={this.handleFilterSelection} checked={this.props.isSelected === 'selected'} scale="m" status="idle"></CalciteCheckbox>
          {this.props.subFilterLabel}
        </calcite-label>
      </li>
    );
  }
}

export default FilterListItem;
