import React, { Component } from 'react';
import * as _ from 'lodash-es';
import Utils, { FilterAccessibility } from '../../sr-utils';
import FilterParentListItem from './FilterParentListItem';

class Filters extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		Utils.getfilterData(props);
		this.activeFilter = null;
		this.filterSearchItems = [];
		this.isFilterHidden = this.props.isFilterCatOpen == 'true' ? false : true;
		this.handleFilterSelection = this.handleFilterSelection.bind(this);
		this.handleClearFilters = this.handleClearFilters.bind(this);
		this.filterFunctionUpdated = this.filterFunctionUpdated.bind(this);
		this.filterclass = '';
		this.filtersDataCopy = this.props.filtersData;
		this.state = {
			filtersData: this.filtersDataCopy,
		};

        document.addEventListener('keydown', function(event) {
          if (event.key === 'Enter'){
            const activeElement = document.activeElement;
            if((activeElement.tagName==="LI" && activeElement.className==="filter-link new") ||
              (activeElement.tagName==="SPAN" && activeElement.classList.contains("filter-heading-clear-link"))){
              activeElement.click();
            }
          }
        });
	}

	componentDidMount() {
		let filterTree = document.querySelector('#filtertree');
		if (filterTree != null) {
			new FilterAccessibility(filterTree);
		}
	}

	handleFilterSelection(e) {
		let target = e.target;
        if (target.tagName === 'CALCITE-CHECKBOX') {
          target = target.parentElement;
        }
		let sField = target.getAttribute('data-field');
		let sValue = target.getAttribute('data-value');
		this.handleFiltersUpdates(sField, sValue);
	}

	handleFiltersUpdates(sField, sValue) {
		let params = new URLSearchParams(window.location.search.slice(1));

		// Search if matching filter and value exists in url
		let filterValues = params.getAll(sField);
		let i = 0;
		let found = false;
		while (i < filterValues.length && !found) {
			if (filterValues[i] === sValue) {
				found = true;
			}
			i++;
		}

		// If matching filter and value exists, delete
		if (found) {
			// This is to format values into urlsearchparams format to deal with special characters
			// and delete the field and value if it exists
			let encodedValue = encodeURIComponent(sValue.replace(/\+/g, ' '));
			let filterTupleToDelete = new URLSearchParams(sField + '=' + encodedValue);
			params = new URLSearchParams(params.toString().replace(filterTupleToDelete.toString(), ''));
		}
		// Else add to url
		else {
			params.append(sField, sValue);
		}
		// In both cases, delete page, p
		params.delete('p');

		window.history.replaceState({}, '', window.location.pathname + '?' + params);
		this.props.handleSubmit();
	}

	handleClearFilters() {
      if(this.props.activeFilters.length > 0){
		let params = new URLSearchParams(window.location.search.slice(1));

		// Gets filter=value pairs
		let filterTuples = params.toString().split('&');

		// Deletes filters
		for (let i = 0; i < filterTuples.length; i++) {
			let filter = filterTuples[i].split('=');
			// Deletes filters include page, p, but not query, q
            // Ignoring gh_src is a special case for careers search and filter
			if (filter[0] !== 'q' && filter[0] !== 'wcmmode' && filter[0] !== 'gh_src') {
				params.delete(filter[0]);
			}
		}

		window.history.replaceState({}, '', window.location.pathname + '?' + params);
		this.props.handleSubmit();
      }
	}

	filterFunctionUpdated(e) {
		let parentFilters = JSON.parse(JSON.stringify(this.filtersDataCopy));
		let target = e.target;
		if (target.value != '') {
			let indx = target.getAttribute('data-index');
			let subFilterCopy = parentFilters[indx].subFilters.slice();
			let input = document.getElementById(`filterSearch-${indx}`);
			let newsubFilterArray = [];
			let filterInput = input.value;
			subFilterCopy.forEach((item) => {
				let filterlabel = item.label;
				if (filterlabel.toLowerCase().indexOf(filterInput.toLowerCase()) > -1) {
					newsubFilterArray.push(item);
				}
			});
			parentFilters[indx].subFilters = newsubFilterArray;
		}
		this.setState({ filtersData: parentFilters });
	}

	render() {
		if (!this.state.filtersData) {
			return null;
		}
		let tempFiltersData = this.state.filtersData;

		let clearFiltersText = this.props.labels.clearFilterLabel;
		let filterheadingLabel = this.props.labels.filterLabel;
		let isChecked = this.props.isFilterCatOpen === 'true';
		let activeFiltersMap = {};

		if (this.props.activeFilters.length) {
			let activeFilters = Utils.getCurrFiltersArr(this.props.activeFilters);
			activeFilters.forEach((_filter) => {
				if (activeFiltersMap[_filter.cat] === undefined) {
					activeFiltersMap[_filter.cat] = {};
				}
				activeFiltersMap[_filter.cat][_filter.value] = true;
			});
		}
		let TopLevelFilters = tempFiltersData.map((filter, indx) => {
			if (filter.subFilters) {
				return (
                  <FilterParentListItem 
                    key={indx}
                    indx={indx}
                    filter={filter}
                    isChecked={isChecked}
                    isFilterHidden={this.isFilterHidden}
                    filterFunctionUpdated={this.filterFunctionUpdated}
                    handleFilterSelection={this.handleFilterSelection}
                    activeFiltersMap={activeFiltersMap}
                  />
				);
			}

			// For parent filter links.
			if (filter.name.indexOf('=') > -1) {
				let filterLabel = filter.label.trim();
				let [filterField, filterName] = filter.name.split('=');
				filterField = filterField.trim();
				filterName = filterName.trim();
				let isSelected = null; // reset
				if (activeFiltersMap[filterField] && activeFiltersMap[filterField][filterName]) {
					isSelected = 'selected';
				}

				return (
					<li
                      key={indx}>
                      <calcite-label
                        layout="inline"
                        class="filter-link filter-link--parent"
                        data-attribute={isSelected}
                        onClick={this.handleFilterSelection}
                        data-field={filterField}
                        data-value={filterName}
                        data-label={filterLabel}
                        tabIndex="0">
                        {isSelected === 'selected' ? 
                          <calcite-checkbox checked scale="m" status="idle" calcite-hydrated=""></calcite-checkbox> :
                          <calcite-checkbox scale="m" status="idle" calcite-hydrated=""></calcite-checkbox>
                        }
                        {filterLabel}
                        </calcite-label>
					</li>
				);
			}
		});

		// check if filters are active, var is used to set clear filter active state
		let hasActiveFilters = _.isEmpty(activeFiltersMap) ? '' : 'selected';

		return (
			<div className="column-5 search-results-app-filter" id="filter" data-expanded="false">
				<div className="filter-heading">
					<div className="filter-heading-text">
						<p>{filterheadingLabel}</p> <span className="close-filter"></span>
					</div>
					<div className="filter-heading-clear">
						<button
                            tabIndex={this.props.activeFilters.length > 0 ? "0" : "-1"}
							onClick={this.handleClearFilters}
							className="link-blue filter-heading-clear-link"
							data-attribute={hasActiveFilters}>
							{clearFiltersText}
						</button>
					</div>
				</div>

				<div className="filter-content">
					<div className="filter-content-navigation">
						<ul id="filtertree">
							{TopLevelFilters}
						</ul>
					</div>
				</div>

				<div className="filter-content-mobile">
					<calcite-button class="filter-heading-close-mobile" href="#">
						See results
					</calcite-button>
					<div className="filter-heading-clear-mobile">
						<a onClick={this.handleClearFilters} className="avenir-regular link-blue">
							{clearFiltersText}
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default Filters;
