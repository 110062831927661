import React from 'react';
import { Page, withModel } from '@adobe/aem-react-editable-components';

// This component is the application entry point

class App extends Page {
	constructor(props) {
		super(props);
		this.propsObj = {
			app: this.props.app,
			appWrapper: this.props.appWrapper,
			productPath: this.props.productPath,
		};
	}

	render() {
		return <div className="aem-react-app">{this.childComponents}</div>;
	}
}

export default withModel(App);
