import React, { useState } from 'react';
import Utils from '../sr-utils';

function SearchOptions(props) {
	const [isSortOpen, setSortState] = useState(false);
	const [sortLabel, setSortLabel] = useState(props.sortValue);

	let total = props.results;

	let msgResults = props.msgNoResults;
	let resultsTotal = total == 1 ? `${total} ${props.labels.resultLabel}` : `${total} ${props.labels.resultsLabel}`;
	resultsTotal = total < 1 ? msgResults : resultsTotal;

	let ActiveResults = null;
	if (props.activeFilters.length) {
		let filters = Utils.getCurrFiltersArr(props.activeFilters);
		ActiveResults = filters.map((filter, indx) => {
			let pillLabel = filter.label.length ? filter.label : filter.value;
			return (
				<calcite-chip key={indx} data-field={filter.cat} data-value={filter.value} appearance="outline" label={`remove ${decodeURI(pillLabel)} filter`} onClick={handleClearFilter} onKeyDown={(e) => handleEnterClearFilter(e)}>
                  <div className="calcite-chip-wrapper">
                  <span>{decodeURI(pillLabel)}</span>
                  <calcite-icon icon="x" scale="s"></calcite-icon>
                  </div>
                </calcite-chip>
			);
		});
	}

	function handleClearFilter(e) {
		let target = e.target.closest('calcite-chip');
		let sField = target.getAttribute('data-field');
		let sValue = target.getAttribute('data-value');

		let params = new URLSearchParams(window.location.search.slice(1));

		// Search if matching filter and value exists in url
		let filterValues = params.getAll(sField);
		let i = 0;
		let found = false;
		while (i < filterValues.length && !found) {
			if (filterValues[i] === sValue) {
				found = true;
			}
			i++;
		}

		// If matching filter and value exists, delete
		if (found) {
			// This is to format values into urlsearchparams format to deal with special characters
			// and delete the field and value if it exists
			let encodedValue = encodeURIComponent(sValue.replace(/\+/g, ' '));
			let filterTupleToDelete = new URLSearchParams(sField + '=' + encodedValue);
			params = new URLSearchParams(params.toString().replace(filterTupleToDelete.toString(), ''));
		}
		// Else add to url
		else {
			params.append(sField, sValue);
		}
		// In both cases, delete page, p
		params.delete('p');

		window.history.replaceState({}, '', window.location.pathname + '?' + params);
		props.handleSubmit();
	}

    function handleEnterClearFilter(e) {
        if (e.key === 'Enter') {
            handleClearFilter(e);
        }
    }

	function handleSortSelection(e) {
		let target = e.target;
		let sortLabel = target.getAttribute('data-label');
		let params = new URLSearchParams(window.location.search.slice(1));
		params.set('s', sortLabel);
		window.history.replaceState({}, '', window.location.pathname + '?' + params);
		props.handleSubmit();
		setSortLabel(sortLabel);
		setSortState(false);
	}

	let SortItems;
	let sortingState = isSortOpen ? 'active' : '';
	if (props.sortData.length > 0) {
		SortItems = props.sortData.map((sortItem, i) => {
			if (i == 0 && sortLabel == null) {
				// set default label
				setSortLabel(sortItem.label);
			}
			let sortItemValue = sortItem.value;
			let sortItemLabel = sortItem.label;
			let isSelected = null; // reset
			let sortId = sortItem.value;
			return (
				<li
					key={i}
					className="sort-option"
					data-selected={sortId}
					data-attribute={isSelected}
					onClick={handleSortSelection}
					data-value={sortItemValue}
					data-label={sortItemLabel}>
					{sortItemLabel}
				</li>
			);
		});
	}
	return (
		<section className="sra-options">
			<div className="sra-tag-filter">
				<div className="sra-options-tag">
				<calcite-chip-group label={`active filters group ${ActiveResults ? ActiveResults.length : 0} filters active`} className="ea-nav-filter-status-tags-wrapper">{ActiveResults}</calcite-chip-group>
				</div>
				<div className="sra-options-filter">
					<div className="sra-options-filter-number" aria-live="polite">{resultsTotal}</div>
				</div>
			</div>
			{sortLabel && (
				<div className="sra-sort-options">
					<div className="avenir-demi font-size-0 sra-sort-options-label">{props.labels.sortLabel}</div>
					<div className="sra-sort-options-wrapper">
						<div className={`select_wrap ${sortingState}`}>
							<span onClick={() => setSortState(!isSortOpen)} className="default_option">
								{sortLabel}
							</span>
							<ul className="select_ul">{SortItems}</ul>
						</div>
					</div>
				</div>
			)}
		</section>
	);
}

export default SearchOptions;
