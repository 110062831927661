class careersNormalizer {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;
		let searchResults = {
			totalResults: rawResults.count,
			offset: rawResults.offset,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}
		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = rawResult.doc;
			card.link = result.metaFields.resourceName || '';
			card.category = result.metaFields.category || '';
			card.featured = result.metaFields.featured || '';
			card.jobTitle = result.metaFields.JobTitle || '';
			card.description = result.metaFields.overview || '';
			card.locations = result.metaFields.locations || '';
			return card;
		});
		return searchResults;
	}
}

export default careersNormalizer;
