import * as _ from 'lodash-es';
import axios from 'axios';

const ISLOCAL = /localhost/gm;


class ApiManager {
	static async getSearchedData(apiPath, payload) {
		let response;
		try {
			response = await axios({
				method: 'post',
				url: `${apiPath}`,
				data: payload,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			console.error(`Search API Error for path ${apiPath}:`, error);
			return { error: `catch API Error: [${error}]` };
		}
		if (!response || !response.data || !response.data.search) {
			console.error('Search API Error: [Empty API response or no API data!!!] API Response:', response);
			return { error: 'API Error: [empty request response!!!]' };
		}
		return response;
	}

	static async getFilters(apiPath, url) {
		let filters;
		if (!url) {
			url = `/bin/esri/filterReader?path=${apiPath}`;
		}
		try {
			filters = await axios({
				method: 'get',
				url: url,
				headers: {
					'Content-Type': 'application/json',
				},
			});
		} catch (error) {
			console.error(`Filters API Error for path /bin/esri/filterReader?path=${apiPath}:`, error);
			return { error: `catch API Error: [${error}]` };
		}
		if (!filters) {
			console.error('Filters API Error: [empty request response!!!]  API Response:', filters);
			return { error: 'API Error: [empty request response!!!]' };
		}
		return filters;
	}
}

export default ApiManager;
