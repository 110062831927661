// Custom filter normalizers can be added here.
// Standard Normalizer will be default.
class filtersNormalizer {
	constructor(filtersData, app) {
		this.filtersData = filtersData;
		this.app = app;
	}

	getNormalizedFilters() {
		return this.normalizedFilters();
	}

	getNormalizedSort() {
		return this.normalizedSort();
	}

	// Standard Normalizer
	normalizedFilters = () => {
		let filters = this.filtersData.Filters.map((filter) => {
			return {
				label: filter.Label,
				name: filter.Name,
				enableFilterSearch: filter.enableFilterSearch,
				searchInputText: filter.filterSearchInputText,
				subFilters: filter.subfilters,
			};
		});
		return filters;
	};

	normalizedSort = () => {
		let sortBy = this.filtersData.Sort.map((sort) => {
			return {
				label: sort.sortLabel,
				value: sort.sortValue,
			};
		});

		return sortBy;
	};
}
export default filtersNormalizer;
