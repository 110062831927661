import React from "react";
export default function CareersCard(props) {
  let link = "";
  let category = "";
  let title = "";
  let pill = "";
  let description = "";
  let button = "";
  let subText = "";
  let dataEvent = "";
  let dataComponentName = "";
  let dataComponentLink = "";
  let dataComponentLinkType = "";
  if (props.app === "careers") {
    let job = props.cardinfo;

    let params = new URLSearchParams(window.location.search.slice(1));
    if (params.has('gh_src')) {
      let gh_src = params.get('gh_src');
      link = job.link + '?gh_src=' + gh_src;
    } else {
      link = job.link;
    }
    if(Array.isArray(job.category)) {
      category = job.category.join(" | ")
    } else {
      category = job.category;
    }
    description = job.description;
    if (description && Array.isArray(description)) {
      description = description.join(", ");
    }
    if (description && description.length > 150) {
      description = description.slice(0, 147) + "...";
    }
    title = job.jobTitle;
    if (title && Array.isArray(title)) {
      title = title.join(", ");
    }
    if (job.featured === "True") {
      pill = <span className="avenir-regular esri-font--2 pill">Featured</span>;
    }
    if (job.locations && Array.isArray(job.locations)) {
      if (job.locations.length === 1) {
        subText = job.locations[0];
      } else {
        subText = props.multipleLocationsText;
      }
    } else {
      subText = job.locations;
    }
    if (props.viewJobText) {
      button = (
        <div className="calcite-button-wrapper">
          <calcite-button class="careers-btn" scale="m" data-primary="true">
            {props.viewJobText}
          </calcite-button>
        </div>
      );
    }
    dataEvent = "track-job-result";
    dataComponentName = "careers-card";
    dataComponentLink = title;
    dataComponentLinkType = "card";
  }
  if (props.app === "support") {
    let supportData = props.cardinfo;
    link = supportData.link;

    let buttonText = "";

    if (supportData.collection === "knowledgebase") {
      category = supportData.category;
      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }
      buttonText = props.viewArticleBtnText || "View article";
      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "gisdictionary") {
      category = supportData.category;
      buttonText = props.viewTermBtnText || "View term on GIS Dictionary";

      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      let taxonomy = "";
      if (supportData["taxonomy1"]) {
        taxonomy = `[${supportData["taxonomy1"]}]`;
      }
      let definition = "";
      if (supportData["definition1"]) {
        definition = supportData["definition1"];
      }
      if (taxonomy.length && definition.length) {
        description = taxonomy + " " + definition;
      } else if (definition.length) {
        description = definition;
      }

      if (description && description.length > 150) {
        description = description.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "bugs") {
      category = supportData.category;
      buttonText = props.viewBugBtnText || "View bug details";
      title = supportData.title;
      subText = supportData.bugId;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      let statuses = {
        "In Review": "warning",
        "Under Consideration": "warning",
        "Non-Reproducible": "danger",
        "Will Not Be Addressed": "danger",
        "In Product Plan": "success",
        Fixed: "success",
        Implemented: "success",
        Duplicate: "neutral",
        "As Designed": "neutral",
        "Known Limit": "neutral",
      };

      let statusIcons = {
        "In Review": "debugScript",
        "Under Consideration": "debugScript",
        "Non-Reproducible": "xOctagon",
        "Will Not Be Addressed": "xOctagon",
        "In Product Plan": "checkCircle",
        Fixed: "checkCircle",
        Implemented: "checkCircle",
        Duplicate: "duplicate",
        "As Designed": "paletteCheck",
        "Known Limit": "exclamationMarkTriangle",
      };

      pill = (
        <calcite-chip
          class={`support-chip support-chip-${statuses[supportData.status]}`}
          scale="s"
          appearance="outline"
          kind="neutral"
          icon={`${statusIcons[supportData.status]}`}
        >
          {supportData.status}
        </calcite-chip>
      );

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "white-papers") {
      category = supportData.category;
      buttonText = props.viewTechPaperBtnText || "View article";

      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "patches-servicepacks") {
      category = supportData.category;
      buttonText = props.viewPatchUpdateBtnText || "View article";
      subText = supportData.date;
      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "help") {
      category = supportData.category;
      buttonText = props.viewProductBtnText || "View article";

      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "blogs") {
      category = supportData.category;
      buttonText = props.viewBlogBtnText || "View blog";

      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }

    if (supportData.collection === "community") {
      category = supportData.category;
      buttonText = props.viewCommunityBtnText || "View post";

      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }
    if (supportData.collection === "videos") {
      category = supportData.category;
      buttonText = props.viewVideosBtnText || "View Videos";

      title = supportData.title;
      if (title && title.length > 150) {
        title = title.slice(0, 147) + "...";
      }

      button = (
        <div className="calcite-button-wrapper">
          <calcite-link scale="m" data-primary="true" icon-end="arrow-right">
            {buttonText}
          </calcite-link>
        </div>
      );
    }
  }
  
  // When new calcite is complete, revisit and add/delete necessary classes.
  return (
    <article className={`card-job-block lighttheme ${props.app}-card`}>
      <a
        href={link}
        className={`card card-wide ${props.app}-link`}
        data-event={dataEvent}
        data-component-name={dataComponentName}
        data-component-link={dataComponentLink}
        data-component-link-type={dataComponentLinkType}
      >
        <div className="card-content card-content-padding">
          <div className={`${props.app}-top`}>
            {pill}
            <p className={`category ${props.app}-category`}>{category}</p>
          </div>
          <div className={`${props.app}-middle`}>
            <h2
              className={`trailer-half avenir-regular esri-font-3 ${props.app}-title`}
            >
              {title}
            </h2>
            <p
              className={`trailer-half avenir-regular esri-font-0 ${props.app}-description`}
            >
              {description}
            </p>
          </div>
          <div className={`${props.app}-bottom`}>
            <p
              className={`avenir-regular esri-font--1 ${props.app}-bottomText`}
            >
              {subText}
            </p>
            {button}
          </div>
        </div>
      </a>
    </article>
  );
}
