import React from 'react';

export default function DistributorCard(props) {
	let card = props.cardinfo;
	let title = card.title;
	let id = card.id;

	return (
		<article className="result lighttheme">
			<a href={card.id} className="card card-wide result__card">
				<div className="card-content result__content-wrapper">
					<h2 className="trailer-half avenir-regular esri-font-3 result__title" dangerouslySetInnerHTML={{ __html: card.title }} />
					<p className="esri-font--1">{card.id}</p>
					<p className="esri-font-1 result__description" dangerouslySetInnerHTML={{ __html: card.description }} />
				</div>
			</a>
		</article>
	);
}
