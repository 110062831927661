import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export default function OmniBuyCard(props) {
	const [priceCase, setPriceCase] = useState(null);

	useEffect(() => {
		// check if price optins have authore

		if (typeof window !== 'undefined' && 'esri_priceCase' in window) {
			let priceCaseObj = window.esri_priceCase;
			// Check if cases are set
			if ('case' in priceCaseObj && priceCaseObj.case !== null) {
				// change state to reflect cases;
				setPriceCase(priceCaseObj.case);
			}
		}
	});

	let card = props.cardinfo;
	let showPrice = props.countrycode;
	let hasProdLink = card.productDetailLink && card.productDetailLabel ? true : false;
	let hasLinkTwo = card.linkcta2 && card.labelcta2 ? true : false;
	let cardColor = card.themeColor || '#007ac2';
	let isCollectionCard = card.isCollection ? ' recommendedCard' : '';
	let productType = null;

	if (Array.isArray(card.productType)) {
		if (card.productType.length > 1) {
			productType = `${card.productType[0]}, ${card.productType[1]}`;
		} else {
			productType = card.productType[0];
		}
	} else {
		productType = card.productType;
	}
	let discountText = Array.isArray(card.discountText)
		? (() => {
				let tempTex = '';
				card.discountText.forEach((txt) => {
					if (txt) {
						if (tempTex === '') {
							tempTex = txt;
						} else {
							tempTex = `${tempTex}, ${txt}`;
						}
					}
				});
				return tempTex;
		  })()
		: card.discountText; // Fix elastic search issue - splits string when string has comas.

	let cardPrice = Array.isArray(card.price)
		? (() => {
				let tempTex = '';
				card.price.forEach((txt) => {
					if (txt) {
						if (tempTex === '') {
							tempTex = txt;
						} else {
							tempTex = `${tempTex},${txt}`;
						}
					}
				});
				return tempTex;
		  })()
		: card.price;
	let hasPriceCase = false;
	// Price restrictions set from buy-card-verification.js (productpricing API)
	if (priceCase !== null && priceCase !== 'ROW') {
		showPrice = hasLinkTwo = null; // hide default price and cta
		hasPriceCase = true; // show custom price mssg
	} else if (priceCase !== null && priceCase === 'ROW') {
		showPrice = null; // hide default price and cta
	}

	return (
		<div className={'card block omni-card' + isCollectionCard} data-card-position={props.cardPosition}>
			<div className="card-filter">
				<div className="lighttheme omni-card-wrapper">
					<div className="omni-card-color-bar" style={{ background: `${cardColor}` }}>
						{/* <div className="card-filter-color-bar-category">{productType}</div>
                        {card.productTag && 
                            <div className="card-filter-color-bar-recommended">{card.productTag}</div>
                        } */}
					</div>

					<div className="card-filter-content">
						<div className="card-filter-text-wrapper">
							<div className="card-filter-content-title">
								<div className="card-filter-content-icon">
									<img
										src={card.productIcon || 'https://www.esri.com/content/dam/esrisites/en-us/common/icons/meridian-/map-pin-star-48.svg'}
										className="nonResp svg"
										style={{ fill: `${cardColor}` }}
										alt={card.title}
                                        width='50'
                                        height='50'
                                        decoding='async'
                                        loading='lazy'
									/>
								</div>

								<div class="card-title-type">
									<div class="type-circle" style={{ background: `${cardColor}` }} alt={card.title}></div>
									<div class="type-text">{productType}</div>
								</div>

								<div className="card-filter-content-text">
									<h2 className="card-title trailer-half  font-size-2">{card.title}</h2>
								</div>
							</div>

							<div className="card-filter-content-description avenir-regular trailer-half">
								<div dangerouslySetInnerHTML={{ __html: card.description }} />
							</div>
						</div>
						<div className={`card-filter-price-wrapper`}>
							{showPrice && (
								<div className="card-filter-content-purchase avenir-regular">
									<p className="purchase-text trailer-0">{cardPrice && card.pricePrefix}</p>
									<p className="purchase-price trailer-0">{cardPrice}</p>
									<p className="purchase-text discount">{cardPrice && discountText}</p>
								</div>
							)}
						</div>
						<div className="cta-section text-right">
							{hasProdLink && (
								<div
									class="calcite-button-wrapper"
									data-component-link-type="button"
									data-event="track-store-component"
									data-component-name="Omni Buy Card"
									data-link-type="internal"
									data-product-type={card.productType}
									data-product-name={card.title}
									data-product-link={card.productDetailLabel}>
									<calcite-button
										href={card.productDetailLink}
										class="cta-one"
										appearance="outline"
										data-position={props.key}
										data-component-grid="">
										{card.productDetailLabel}
									</calcite-button>
								</div>
							)}

							{hasLinkTwo && (
								<div
									class="calcite-button-wrapper"
									data-component-link-type="button"
									data-event="track-store-component"
									data-component-name="Omni Buy Card"
									data-product-type={card.productType}
									data-product-name={card.title}
									data-product-link={card.labelcta2}
									data-link-type="internal">
									<calcite-button href={card.linkcta2} appearance="solid" class="cta-two" data-position={props.key} data-component-grid="">
										{card.labelcta2}
									</calcite-button>
								</div>
							)}

							{hasPriceCase && (
								<div
									class="calcite-button-wrapper"
									data-component-name="Omni Buy Card"
									data-component-link-type="button"
									data-link-type="internal">
									<calcite-button href={priceCase.linkURL} className="cta-special-case" appearance="solid" data-component-grid="">
										{priceCase.btnLabel}
									</calcite-button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
