export default class LocalizationLabels {
	// omni labels
	static omni(props) {
		const labels = {
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
			sortLabel: props.sortLabel,
		};

		return labels;
	}

	// there will be labels for other applications
	static partners(props) {
		const labels = {
            updatedResultsText: props.updatedResultsText,
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
		};

		return labels;
	}

	static careers(props) {
		const labels = {
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
		};

		return labels;
	}

	static marketplace(props) {
		const labels = {
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
			sortLabel: props.sortLabel,
		};

		return labels;
	}

	static distributors(props) {
		const labels = {
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
		};

		return labels;
	}

	static press(props) {
		const labels = {
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
		};

		return labels;
	}

	static support(props) {
		const labels = {
			resultsLabel: props.resultsLabel,
			resultLabel: props.resultLabel,
			clearFilterLabel: props.clearFilterLabel,
			filterLabel: props.filterLabel,
			searchInputMessage: props.searchInputMessage,
			promoSectionHeading: props.promoSectionHeading,
			applicationName: props.applicationName,
		};
		return labels;
	}
}
