import React, { Component } from 'react';

class ShowAllToggle extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.textShowAll = "Show all";
    this.textShowLess = "Show less";
    this.state ={
      showAllFilters: false,
    }
  }

  componentDidMount() {

  }

  toggleShowAll = (e) => {
    e.preventDefault();
    this.props.handleDisplayFilterListItems();
    this.setState({showAllFilters: !this.state.showAllFilters});
  }

  keyPressToggleShowAll = (e) => {
    if (e.target.classList.contains('showAllToggle') && e.key === 'Enter') {
      this.toggleShowAll(e);
    }
  }
  
  showAllButtonText = () => {
    if (!this.state.showAllFilters) {
      return this.textShowAll;
    } else {
      return this.textShowLess;
    }
  }

  render() {
    return (
      <>
        <calcite-link 
          class="showAllToggle" 
          aria-hidden={this.props.isFilterHidden} 
          onClick={this.toggleShowAll}
          onKeyDown={this.keyPressToggleShowAll}
          tabIndex="0">{this.showAllButtonText()}
        </calcite-link>
      </>
    )
  }
}

export default ShowAllToggle;
