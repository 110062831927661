import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ModelManager, Constants } from '@adobe/aem-spa-page-model-manager';
import App from './App';
import './MappedComponents';
import aemReactPolyfill from '../../aem-react-polyfill';
// Solve ModelManager issues on ie 11
aemReactPolyfill.init();
console.info('Allowed: [SRA: v.3.7]');

let searchResults = [].slice.call(document.querySelectorAll('.search-result-wrapper'));
if (searchResults && searchResults.length) {
	searchResults.forEach((appWrapper) => {
		function render(model) {
			ReactDOM.render(
				<App
					cqChildren={model[Constants.CHILDREN_PROP]}
					cqItems={model[Constants.ITEMS_PROP]}
					cqItemsOrder={model[Constants.ITEMS_ORDER_PROP]}
					cqPath={ModelManager.rootPath}
					appWrapper={appWrapper}
				/>,
				appWrapper
			);
		}

		ModelManager.initialize({ path: process.env.REACT_APP_PAGE_MODEL_PATH }).then(render);
	});
}
