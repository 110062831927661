import React, { Component } from 'react';
import * as _ from 'lodash-es';

class Pagination extends Component {
	constructor(props) {
		super(props);
		this.pagination = React.createRef();
	}

	componentDidMount() {
		if (this.pagination.current) {
			this.pagination.current.addEventListener('calcitePaginationChange', this.offsetPage);
		}
	}

	componentWillUnmount() {
		if (this.pagination.current) {
			this.pagination.current.removeEventListener('calcitePaginationChange', this.offsetPage);
		}
	}

	componentDidUpdate() {}

	updateUrl = (e,newOffset = 0) => {
		e.preventDefault();
		let newPage = newOffset / this.props.resultsPerPage + 1;

		let params = new URLSearchParams(window.location.search.slice(1));
		params.set('p', newPage);
		window.history.replaceState({}, '', window.location.pathname + '?' + params);
		this.props.handleSubmit();
	};

	offsetPage = (event) => {
		const start = event?.detail?.start || event?.target?.startItem;
		const newOffset = start ? start - 1 : 0;
		this.updateUrl(event,newOffset);
		window.scrollTo({
			top: 0,
		});
	};

	render() {
		const pageAmount = Math.ceil(this.props.totalResults / this.props.resultsPerPage);
        const activePageNumber = Math.ceil(this.props.pageOffset / this.props.resultsPerPage) + 1;
        const activePageUpdateText = this.props.updatedPageNumber +' '+ activePageNumber;

		return (
          <div>
			<calcite-pagination
				class={`pagination-wrapper ${pageAmount <= 1 ? 'hide' : ''}`.trim()}
				ref={this.pagination}
				num={this.props.resultsPerPage}
				page-size={this.props.resultsPerPage}
				start={this.props.pageOffset + 1}
				start-item={this.props.pageOffset + 1}
				total={this.props.totalResults}
				total-items={this.props.totalResults}
                active-page-number={`${activePageNumber}`}
				group-separator></calcite-pagination>
                <span aria-live="polite" class="assistText">{activePageUpdateText}</span>
          </div>
		);
	}
}

export default Pagination;
