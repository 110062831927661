import React, { Component } from 'react';
import * as _ from 'lodash-es';
import Loader from '../global/Loader';
import OmniBuyCard from './results-cards/OmniBuyCard';
import PartnersCard from './results-cards/PartnersCard';
import CareersCard from './results-cards/CareersCard';
import MarketplaceCard from './results-cards/MarketplaceCard';
import DistributorCard from './results-cards/DistributorCard';
import PressCard from './results-cards/PressCard';
import Utils from '../../sr-utils';

class Results extends Component {
	constructor(props) {
		super(props);
		// map application
		this.state = {};
		this.cardOptions = {
			omni: OmniBuyCard,
			partners: PartnersCard,
			careers: CareersCard,
			press: PressCard,
			marketplace: MarketplaceCard,
			distributors: DistributorCard,
			support: CareersCard,
		};
		this.checkCountryCode();
	}

	componentDidMount() {
		if (this.props.app === 'omni') {
			this.setAnaliticsGridAttr();
			jQuery(window).on('resize', _.debounce(this.setAnaliticsGridAttr, 150));
		}
	}
	componentDidUpdate() {
		if (this.props.app === 'omni') {
			_.delay(this.setAnaliticsGridAttr, 150);
		}
	}

	setAnaliticsGridAttr() {
		let cardsContainer = document.querySelector('.search-results-app-cards');
		if (cardsContainer) {
			let cardContainerWidth = cardsContainer.offsetWidth;
			let cards = [].slice.call(document.querySelectorAll('[data-card-position]'));
			if (cards.length) {
				let cardWidth = cards[0].offsetWidth;
				let rowMax = Math.floor(cardContainerWidth / cardWidth);
				let currRow = 1;
				let currColumn = 1;
				cards.forEach((card, indx) => {
					currRow = indx != 0 && indx % rowMax === 0 ? currRow + 1 : currRow;
					let targetBttns = [].slice.call(card.querySelectorAll('[data-component-grid]'));
					targetBttns.forEach((bttn) => {
						bttn.setAttribute('data-component-grid', `r${currRow}-c${currColumn}`);
					});
					currColumn = currColumn == rowMax ? 1 : ++currColumn;
				});
			}
		}
	}

	async checkCountryCode() {
		try {
			let countryCode = await Utils.getCountryCode();
			this.setState({ countryCode });
		} catch (e) {
			console.error('ERROR: countryCode error: ', e);
		}
	}

	render() {
		if (this.props.activateLoader || this.props.cardsData == null) {
			return (
				<div className="search-results-app-cards calcite-theme-light pre-1 post-1">
					<section className="filter-cards">
						<Loader flex={'true'} />
					</section>
				</div>
			);
		}

		let cardsData = this.props.cardsData;
    
		// problem with props data
		if (cardsData.totalResults == undefined || cardsData.resultCards == undefined) {
			return (
				<div className="search-results-app-cards pre-1 post-1">
					<section className="filter-cards">
						<h2 className="results-header font-size-4">{this.props.apiErrorText}</h2>
					</section>
				</div>
			);
		}

		// O results
		if (cardsData.totalResults == 0) {
			return (
				<div className="search-results-app-cards pre-1 post-1">
					<section className="filter-cards">
						<h2 className="results-header font-size-4">{this.props.noResultsText}</h2>
					</section>
				</div>
			);
		}

		let countryCode = '';
		if (this.state.countryCode) {
			countryCode = this.state.countryCode;
		}
		let rawResultCards = cardsData.resultCards;
		let ResultCards = rawResultCards.map((card, indx) => {
			let CurrCard = this.cardOptions[this.props.app];
			return (
				<CurrCard
					key={indx}
					cardinfo={card}
					cardPosition={indx + 1}
					countrycode={countryCode}
					dataApiPath={this.props.dataApiPath}
					multipleLocationsText={this.props.multipleLocationsText}
					viewJobText={this.props.viewJobText}
					multiplePresentersText={this.props.multiplePresentersText}
					sessionBtnText={this.props.sessionBtnText}
					viewArticleBtnText={this.props.viewArticleBtnText}
					viewTermBtnText={this.props.viewTermBtnText}
					viewBugBtnText={this.props.viewBugBtnText}
					viewTechPaperBtnText={this.props.viewTechPaperBtnText}
					viewPatchUpdateBtnText={this.props.viewPatchUpdateBtnText}
					viewProductBtnText={this.props.viewProductBtnText}
					viewBlogBtnText={this.props.viewBlogBtnText}
					viewCommunityBtnText={this.props.viewCommunityBtnText}
                    viewVideosBtnText= {this.props.viewVideosBtnText}
					app={this.props.app}
				/>
			);
		});

		if (this.props.app === 'distributors') {
			return (
				<section className="filter-cards leader-1 trailer-1 pre-1 post-1 calcite-animate calcite-animate__in-up" data-total-cards={ResultCards.length}>
					{ResultCards}
				</section>
			);
		}

		if (this.props.app === 'careers' || this.props.app === 'support') {
			return (
				<div
					className="card-container filter-cards leader-1 trailer-1 pre-1 post-1 calcite-animate calcite-animate__in-up"
					data-total-cards={ResultCards.length}>
					{ResultCards}
				</div>
			);
		}

		if (this.props.app === 'partners') {
			return (
				<div aria-live='polite' className="card-container leader-1 calcite-animate calcite-animate__in-up" data-total-cards={ResultCards.length}>
					{ResultCards}
				</div>
			);
		}

		if (this.props.app === 'omni') {
			return (
				<div className="search-results-app-cards pre-1 post-1" data-total-cards={ResultCards.length}>
					<section className="filter-cards">
						<h2 className="results-header font-size-4"></h2>

						<div className="block-group block-group-3-up tablet-block-group-2-up phone-block-group-1-up searchContainer calcite-animate calcite-animate__in-up">
							{ResultCards}
						</div>
					</section>
				</div>
			);
		}

		if (this.props.app === 'marketplace') {
			return (
				<div className="marketplace-card pre-1 post-1 calcite-theme-light" data-total-cards={ResultCards.length}>
					<div className="marketplace-cards block-group block-group-3-up tablet-block-group-2-up phone-block-group-1-up leader-1 padding-2 calcite-animate calcite-animate__in-up">
						{ResultCards}
					</div>
				</div>
			);
		}

		if (this.props.app === 'press') {
			return (
				<div className="card-container leader-1" data-total-cards={ResultCards.length}>
					<div className="presscard-wrapper padding-x--4 calcite-animate calcite-animate__in-up">{ResultCards}</div>
				</div>
			);
		}
	}
}

export default Results;
