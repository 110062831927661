import React from 'react';

export default function PressCard(props) {
	let book = props.cardinfo;

	let bookTitle = book.title;

	if (bookTitle && Array.isArray(bookTitle)) {
		bookTitle = bookTitle.join(', ');
	}

	let authors = book.authors;

	if (authors && Array.isArray(authors)) {
		authors = authors.join(', ');
	}

	// When new calcite is complete, revisit and add/delete necessary classes.
	return (
		<a href={book.url} class="presscard__link">
			<div
				class="light-mode presscard"
				data-gs-trigger="true"
				data-gs-delay="0.2"
				data-gs-anim="fade-in"
				data-gs-speed="1.0"
				style={{ visibility: 'visible', opacity: 1 }}>
				<div class="presscard__image">
					<img loading="lazy" alt={bookTitle + ' Cover'} src={book.image} />
				</div>
				<div class="presscard__content padding--4">
					<h2 class="presscard__title font-size-1 ui-text-1">{bookTitle}</h2>
					<p class="presscard__author font-size--2 ui-text-3 padding-y--2">by {authors}</p>
				</div>
			</div>
		</a>
	);
}
