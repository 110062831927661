import React from "react";
import PropTypes from "prop-types";
/**
 * @typedef {Object} CalciteChipProps
 * @property {string} [className] - The class name to apply to the component.
 * @property {React.ReactNode}
 * @property {"outline" | "outline-fill" | "solid"} [apperance] - The appearance of the chip.
 * @property {boolean} [closable] - Whether the chip is closable.
 * @property {boolean} [closed] - Whether the chip is closed.
 * @property {string} [icon] - The icon for the chip.
 * @property {boolean} [iconFlipRtl] - Whether the icon should flip in RTL.
 * @property {"brand" | "inverse" | "neutral"} [kind] - The kind of the chip.
 * @property {string} [messageOverrides] - The message overrides for the chip.
 * @property {"s" | "m" | "l"} [scale] - The scale of the chip.
 * @property {string} [value] - The value of the chip.
 *
 * @typedef {Object} CalciteChipEvents
 * @property {CustomEvent} onChipClose - The event fired when the chip is closed.
 */
/**
 * A component that represents a chip.
 * @param {CalciteChipProps & CalciteChipEvents} props - The props.
 * @returns {JSX.Element} The component.
 * @example
 * <CalciteChip
 *  appearance="outline"
 *  closable={true}
 *  closed={false}
 *  icon="circle"
 *  iconFlipRtl={false}
 *  kind="brand"
 *  messageOverrides="Message overrides"
 *  scale="m"
 *  value="Value"
 *  onChipClose={(event) => console.log(event.detail)}
 * >
 *  Children
 * </CalciteChip>
 */
const CalciteChip = ({
  className,
  children,
  appearance,
  closable,
  closed,
  icon,
  iconFlipRtl,
  kind,
  messageOverrides,
  scale,
  value,
  onChipClose,
  ...rest
}) => {
  const chipRef = React.useRef(null);

  // on component mount
  React.useEffect(() => {
    const chip = chipRef.current;

    const onCloseHandler = onChipClose;
    if (onCloseHandler) chip.addEventListener("calciteChipClose", onCloseHandler);

    // on component unmount
    return () => {
      if (onCloseHandler) chip.removeEventListener("calciteChipClose", onCloseHandler);
    };
  });

  return (
    <calcite-chip
      ref={chipRef}
      class={className || undefined}
      appearance={appearance || undefined}
      closable={closable || undefined}
      closed={closed || undefined}
      icon={icon || undefined}
      icon-flip-rtl={iconFlipRtl || undefined}
      kind={kind || undefined}
      message-overrides={messageOverrides || undefined}
      scale={scale || undefined}
      value={value || undefined}
      {...rest}
    >
      {children}
    </calcite-chip>
  );
};

CalciteChip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  appearance: PropTypes.oneOf(["outline", "outline-fill", "solid"]),
  closable: PropTypes.bool,
  closed: PropTypes.bool,
  icon: PropTypes.string,
  iconFlipRtl: PropTypes.bool,
  kind: PropTypes.oneOf(["brand", "inverse", "neutral"]),
  messageOverrides: PropTypes.string,
  scale: PropTypes.oneOf(["s", "m", "l"]),
  value: PropTypes.string,
};

export default CalciteChip;
