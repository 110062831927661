import React, { Component } from 'react';
import FilterListTitle from './FilterListTitle';
import FilterListWrapper from './FilterListWrapper';

class FilterParentListItem extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      isFilterHidden: this.props.isFilterHidden,
    }
    this.filterName = this.props.filter.name.trim();
    this.filterLabel = this.props.filter.label.trim();
    let enableFilterSearch = this.props.filter.enableFilterSearch;
    this.searchInputText = this.props.filter.searchInputText;
    this.isParentSelected = null;
    this.searchClass = '';
    if (enableFilterSearch == 'true') {
        this.searchClass = '';
    } else {
      this.searchClass = 'hide';
    }
  }

  componentDidMount() {

  }

  toggleFilterExpansion = () => {
    this.setState({isFilterHidden: !this.state.isFilterHidden});
  }

  keyPressToggleFilterExpansion = (e) => {
    if (e.target.classList.contains("filter-parent-list-item") && e.key === 'Enter') {
      this.toggleFilterExpansion();
    }
  }

  filterFunctionUpdated = (e) => {
    this.props.filterFunctionUpdated(e);
  }

  handleFilterSelection = (e) => {
    this.props.handleFilterSelection(e);
  }

  render() {
    this.props.filter.subFilters.forEach((subFilter, i) => {
      let subFilterValue = subFilter.value.trim();
      if (this.props.activeFiltersMap[this.filterName] && this.props.activeFiltersMap[this.filterName][subFilterValue]) {
        this.isParentSelected = 'selected';
      }
    });

    return (
      <li className="collapse filter-parent-list-item" tabIndex="0" data-attribute={this.isParentSelected} data-collapsed={this.state.isFilterHidden} onKeyDown={this.keyPressToggleFilterExpansion}>
        {this.props.isChecked ? <input type="checkbox" aria-hidden="true" readOnly checked id={this.filterName} /> : <input type="checkbox" aria-hidden="true" readOnly id={this.filterName} />}
        <FilterListTitle
          filterName={this.filterName}
          indx={this.props.indx}
          isParentSelected={this.isParentSelected}
          isFilterHidden={this.state.isFilterHidden}
          filterLabel={this.filterLabel}
          toggleFilterExpansion={this.toggleFilterExpansion}
        />
        <FilterListWrapper
          isFilterHidden={this.state.isFilterHidden}
          searchClass={this.searchClass}
          dataIndex={this.props.indx}
          searchInputText={this.searchInputText}
          filterFunctionUpdated={this.filterFunctionUpdated}
          filterName={this.filterName}
          filter={this.props.filter}
          activeFiltersMap={this.props.activeFiltersMap}
          handleFilterSelection={this.handleFilterSelection}
        />
      </li>
    );
  }
}

export default FilterParentListItem;