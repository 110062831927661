import React from "react";
import PropTypes from "prop-types";

/**
 * @typedef {Object} CalciteLoaderProps
 * @property {boolean} [inline] - Whether the loader is inline.
 * @property {string} [label] - The label for the loader.
 * @property {"l" | "m" | "s"} [scale] - The scale of the loader.
 * @property {string} [text] - The text for the loader.
 * @property {"determinate" | "determinate-value" | "indeterminate"} [type] - The type of the loader.
 * @property {number} [value] - The value of the loader.
 */
const CalciteLoader = (props) => {
  return (
    <calcite-loader
      data-testid="calcite-loader"
      inline={props.inline || undefined}
      label={props.label || undefined}
      scale={props.scale || undefined}
      text={props.text || undefined}
      type={props.type || undefined}
      value={props.value || undefined}
    />
  );
};

CalciteLoader.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.string,
  scale: PropTypes.oneOf(["l", "m", "s"]),
  text: PropTypes.string,
  type: PropTypes.oneOf(["determinate", "determinate-value", "indeterminate"]),
  value: PropTypes.number,
};

export default CalciteLoader;
