class partnersNormalize {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;
		let searchResults = {
			totalResults: rawResults.count,
			offset: rawResults.offset,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}
		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = rawResult.doc;
			card.title = result.title || '';
			let location = '';
			if (result.metaFields.location && result.metaFields.location.length) {
				location = result.metaFields.location.join(', ');
			}
			let releaseReady = false;
			let resultMetaField = result.metaFields['specialty'];
			let pillText = {
				'ArcGIS System Ready Specialty': 'ArcGIS System Ready',
				'Release Ready Specialty': 'Release Ready',
			};

			if (resultMetaField) {
				let speciality = '';
				if (Array.isArray(resultMetaField)) {
					for (let i = 0; i < resultMetaField.length && !releaseReady; i++) {
						speciality = resultMetaField[i];
						if (pillText[speciality]) {
							releaseReady = pillText[speciality];
						}
					}
				}
				if (typeof resultMetaField === 'string' || resultMetaField instanceof String) {
					if (pillText[resultMetaField]) {
						releaseReady = pillText[resultMetaField];
					}
				}
			}
			card.location = location || '';
			card.program = result.program || '';
			card.img = result.metaFields['og:image'] || '';
			card.description = result.description || '';
			card.link = result.metaFields.resourceName || '';
			card.releaseReady = releaseReady;
			card.partnerTier = result.metaFields['partner-tier'] || '';
            if(card.partnerTier === 'Complementary Technology') {
              card.partnerTier = 'Technology';
            }
			card.solutionType = result.metaFields['solution-type'] || '';
			card.pageType = result.metaFields['page-type'] || '';
			return card;
		});
		return searchResults;
	}
}

export default partnersNormalize;
