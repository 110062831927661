import React from 'react';

export default function Loader(props) {
	const LoaderOnly = () => {
		return (
			<calcite-loader text={Granite.I18n.get('Loading...')} label={Granite.I18n.get('Loading...')} active="true" type="indeterminate"></calcite-loader>
		);
	};

	if (props.flex == 'true') {
		return (
			<div style={{ minWidth: '100%' }}>
				<LoaderOnly />
			</div>
		);
	}
	return (
		<div className="grid-container">
			<div className="column-24 phone-column-6 tablet-column-12 tablet-leader-1">
				<LoaderOnly />
			</div>
		</div>
	);
}
