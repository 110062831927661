import React from "react";
import CalciteLoader from "../../../shared/CalciteLoader";

export default function Loader(props) {
  const LoaderOnly = () => {
    return (
      <CalciteLoader
        text={Granite.I18n.get("Loading...")}
        label={Granite.I18n.get("Loading...")}
        type="indeterminate"
      />
    );
  };

  if (props.flex == "true") {
    return (
      <div style={{ minWidth: "100%" }}>
        <LoaderOnly />
      </div>
    );
  }
  return (
    <div className="grid-container">
      <div className="column-24 phone-column-6 tablet-column-12 tablet-leader-1">
        <LoaderOnly />
      </div>
    </div>
  );
}
