import OmniNormalizer from './omniNormalizer';
import PartnersNormalizer from './partnersNormalizer';
import CareersNormalizer from './careersNormalizer';
import MarketplaceNormalizer from './marketplaceNormalizer';
import DistributorsNormalizer from './distributorsNormalizer';
import PressNormalizer from './pressNormalizer';
import SupportNormalizer from './supportNormalizer';
class Normalizer {
	constructor(props) {
		this.normalizers = {
			omni: OmniNormalizer,
			partners: PartnersNormalizer,
			careers: CareersNormalizer,
			marketplace: MarketplaceNormalizer,
			distributors: DistributorsNormalizer,
			press: PressNormalizer,
			support: SupportNormalizer,
		};
		this.data = props.data;
		this.app = props.app;
	}

	getNormalizedData() {
		let currNormalizer = this.normalizers[this.app];
		let normalizer = new currNormalizer(this.data);
		return normalizer.normalizedData();
	}
}
export default Normalizer;
