// eam-react uses some methods that are not supported on ie11:
// two are known: startsWith and endsWith
// will use this file to track compatability issues with aem-react component

export default class aemReactPolyfill {
	static init() {
		// endsWith comparability issue
		if (!String.prototype.endsWith) {
			String.prototype.endsWith = function (searchString, position) {
				var subjectString = this.toString();
				if (typeof position !== 'number' || !isFinite(position) || Math.floor(position) !== position || position > subjectString.length) {
					position = subjectString.length;
				}
				position -= searchString.length;
				var lastIndex = subjectString.indexOf(searchString, position);
				return lastIndex !== -1 && lastIndex === position;
			};
		}

		// endsWith comparability issue
		if (!String.prototype.startsWith) {
			String.prototype.startsWith = function (searchString, position) {
				position = position || 0;
				return this.substr(position, searchString.length) === searchString;
			};
		}
	}
}
