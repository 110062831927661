class supportNormalizer {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;
		let searchResults = {
			totalResults: rawResults.count,
			offset: rawResults.offset,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}
		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = rawResult.doc;

			card.collection = result.metaFields['search-collection'] || '';

			if (card.collection === 'knowledgebase') {
				card.link = result.metaFields.resourceName || '';
				card.category = result.metaFields['article-type'] || '';
				card.title = result.metaFields['search-title'];
			}

			if (card.collection === 'gisdictionary') {
				card.link = result.metaFields.resourceName || '';
				card.category = result.metaFields.category || 'GIS Dictionary Definition';
				card.title = result.metaFields['FullDisplayTerm'] || '';
				if (result.description) {
					if (Array.isArray(result.description)) {
						card.description = result.description.join(', ');
					} else {
						card.description = result.description;
					}
				} else {
					card.description = '';
				}
				for (let i = 1; i <= 5; i++) {
					if (result.metaFields['Definition' + i]) {
						if (Array.isArray(result.metaFields['Definition' + i])) {
							card['definition' + i] = result.metaFields['Definition' + i].join(', ');
						} else {
							card['definition' + i] = result.metaFields['Definition' + i];
						}
					}
					if (result.metaFields['Taxonomy' + i]) {
						if (Array.isArray(result.metaFields['Taxonomy' + i])) {
							card['taxonomy' + i] = result.metaFields['Taxonomy' + i].join(', ');
						} else {
							card['taxonomy' + i] = result.metaFields['Taxonomy' + i];
						}
					}
				}
			}

			if (card.collection === 'bugs') {
				card.link = result.metaFields.resourceName || '';
                if(result.metaFields.cardtype === 'enhancement') {
                    card.category = result.metaFields.category || 'Enhancement';
                } else {
                    card.category = result.metaFields.category || 'Bug';
                }
                if(result.metaFields.synopsis && Array.isArray(result.metaFields.synopsis)) {
                    result.metaFields.synopsis = result.metaFields.synopsis.join(' ');
                }
				card.title = result.metaFields.synopsis || '';
				card.status = result.metaFields.status || '';
                card.bugId = result.metaFields['bug-id'] || '';
			}
            
            if (card.collection === 'white-papers') {
                card.link = result.metaFields.resourceName || '';
                card.category = 'Technical Papers';
                card.title = result.metaFields['dc:title'] || '';
            }

            if(card.collection === 'patches-servicepacks') {
                card.link = result.metaFields.resourceName || '';
                card.category = 'Patches and Updates';
                card.title = result.metaFields['dc:title'] || '';
                if (
                  result.metaFields["date-sort"] &&
                  result.metaFields["date-sort"].length === 10
                ) {
                    card.date = this.formatDate(result.metaFields["date-sort"]);
                } else {
                    card.date = '';
                }
            }

            if(card.collection === 'products' || card.collection === 'help') {
              card.link = result.metaFields.resourceName || '';
              card.category = 'Product';
              card.title = result.metaFields['dc:title'] || '';
            }

            if(card.collection === 'blogs') {
              card.link = result.metaFields.resourceName || '';
              card.category = 'Blog';
              card.title = result.metaFields['dc:title'] || '';
            }
            
            if(result.collection.includes('community_esri')) {
              card.collection = 'community';
              card.link = result.metaFields.resourceName || '';
              card.category = 'Community';
              card.title = result.metaFields['dc:title'] || '';
            }
            if(result.collection.includes('esri_videos_kaltura')) {
              card.collection = 'videos';
              card.link = result.metaFields.resourceName || '';
              card.category = 'Videos';
              card.title = result.title || '';
            }

			return card;
		});

		return searchResults;
	}

    formatDate(date) {
        let months = {
            '01': 'January',
            '02': 'February',
            '03': 'March',
            '04': 'April',
            '05': 'May',
            '06': 'June',
            '07': 'July',
            '08': 'August',
            '09': 'September',
            '10': 'October',
            '11': 'November',
            '12': 'December'
        }

        let [year, month, day] = date.split("-");
        return `${months[month]} ${day}, ${year}`;

    }
}

export default supportNormalizer;
