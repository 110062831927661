import React from 'react';

export default function ErrorMesage(props) {
	return (
        <div class="grid-container search-results-app-content">
            <div class="column-19 search-results-app-results">
                <div className="search-results-app-cards pre-1 post-1">
                    <section className="filter-cards">
                        <h2 className="font-size-4">{props.error}</h2>
                    </section>
                </div>
            </div>
        </div>
);
}
