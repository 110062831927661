import React, { Component } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import * as _ from 'lodash-es';
import SearchConfig from './config/config';
import Normalizer from './normalizer/Normalizer';
import LocalizationLabels from './Localization';
import ApiManager from './ApiManager';
import Loader from './components/global/Loader';
import Filters from './components/Filter/Filters';
import SingleBarSearch from './components/search/SingleBarSearch';
import SearchOptions from './components/SearchOptions';
import Results from './components/results/Results';
import ErrorMessage from './components/global/ErrorMessage';
import { filterDomManipulations } from './sr-utils';
import Pagination from './components/global/Pagination';
import FiltersNormalizer from './filtersNormalizer/filtersNormalizer';

class SearchResultsApp extends Component {
	constructor(props) {
        super(props);
		this.app = props.applicationName;
		this.filtersNeeded = true;
		if (this.app === 'distributors') {
			this.filtersNeeded = false;
		}
		this.page = this.getPagePath();
		this.resultsMsg = this.getResultsMsg();
		this.dataApiPath = props.search_api_path;
		this.filtersAPIUrl = props.filtersapiurl;
		this.featuredAPIUrl = props.featuredapiurl;
		this.localizationLabels = LocalizationLabels[this.app](props);
		this.activeFilters = []; // active chiclets //TODO will come from author dialog
		this.activeSearchQ = null;
		this.appConfig = SearchConfig[this.app](props);
		this._defaultPayload = this.appConfig.defaultPayload;
		this.searchData = {};
		this.featuredList = [];
		this.filtersData = {};
		this.sortData = {};
		this.filterKeys = {};
		this.normalizer;
		this.filtersNormalizer;
        this.sortValue = null;
		this.state = {
			resultsData: [],
			filtersData: [],
			initQvalue: '',
			error: null,
			activeCallOn: false,
		};

		this.handleSearchChange = this.handleSearchChange.bind(this);
	}

	componentDidMount() {
		this.getAppData();

		// Trigger dom JS (animations and such)
		filterDomManipulations();
	}

	componentDidUpdate() {
		// Trigger dom JS (animations and such)
		filterDomManipulations();
		jQuery(window).on('resize', _.debounce(filterDomManipulations, 150));
	}

	async getAppData() {
		try {
			if (this.filtersNeeded) {
				await this.getFiltersData();
				this.payload = this.getPayload();
			} else {
				this.payload = this.getPayload();
			}
			await this.setResultsData();
		} catch (error) {
			this.setState({ error });
		}
	}

	async getFiltersData() {
		let rawFilters = {};
		try {
			rawFilters = await ApiManager.getFilters(this.page, this.filtersAPIUrl);
		} catch (error) {
			let currentError = error.toString();
			console.error('ERROR:', currentError);
			this.setState({ error });
		}
		if (rawFilters.error) {
			this.setState({ error: rawFilters.error });
		} else {
            this.filtersNormalizer = new FiltersNormalizer(rawFilters.data);
            this.filtersData = this.filtersNormalizer.getNormalizedFilters();
            if (rawFilters.data.Sort) {
                this.sortData = this.filtersNormalizer.getNormalizedSort();
            }

            // Gets all filter names as set as filter keys
            this.filterKeys = this.filtersData.reduce((filters, filter) => {
                if (filter.subFilters) {
                    filters[filter.name] = filter.name;
                }
                if (filter.name.indexOf('=') > -1) {
                    let filterName = filter.name.split('=')[0];
                    filters[filterName] = filterName;
                }
                return filters;
            }, {});
        }
		return;
	}

	async setResultsData() {

		let params = new URLSearchParams(window.location.search.slice(1));

		// Check if alwaysSort parameter isn't true.  If so, sort only if there
		// is NO query, q.  If there is query, then delete the sort parameter from payload.
		// This makes results in order of relevance to query and sorted by sortParameter
		// if no query exists.
		if (!this.appConfig.alwaysSort) {
			if (!params.has('q') && this.appConfig.sort && this.appConfig.sortParameter && !params.has('s')) {
				this.payload['sort'] = this.appConfig.sortParameter;
			} else if (!params.has('s')) {
				delete this.payload['sort'];
			}
		}

		// Calculates pagination for payload
		if (this.appConfig.pagination) {
			let params = new URLSearchParams(window.location.search.slice(1));
			if (params.has('p')) {
				this.payload.start = parseInt(params.get('p')) * this.appConfig.resultsPerPage - this.appConfig.resultsPerPage;
			}
		}

		if (!this.dataApiPath) {
			this.setState({ error: 'Search API seems to be missing! Please add a correct search API' });
			return;
		}
		try {
			this.searchData = await ApiManager.getSearchedData(this.dataApiPath, this.payload);
		} catch (error) {
			let currentError = error.toString();
			console.error('ERROR:', currentError);
		}
		if (this.searchData) {
			let _props = {
				data: this.searchData,
				app: this.app,
			};
			this.normalizer = new Normalizer(_props);
			let resultsData = this.normalizer.getNormalizedData();
			let params = new URLSearchParams(window.location.search.slice(1));
			let initQvalue = '';
			if (params.has('q')) {
				let qValue = params.get('q');
				const searchValue = '%2E';
				let periodIndex = qValue.indexOf(searchValue);
				if (periodIndex > -1) {
                    qValue = qValue.replace(/%2E/g,'.');
				}
				initQvalue = qValue;
			}
			let filtersData = this.filtersData;
			let activeCallOn = false;
			this.setState({ resultsData, filtersData, initQvalue, activeCallOn });
		}
	}

	getPagePath() {
		let appWrapper = document.querySelector('[data-pagepath]');
		return appWrapper.getAttribute('data-pagepath');
	}

	getResultsMsg() {
		let msgResults = document.querySelector('.no-results-i18n');
		return msgResults.innerHTML;
	}

	getInitHashQuery() {
		let initSQ = '';
		let defaultQuery = 'search=';
		let currHash = decodeURI(location.hash);
		if (currHash.indexOf(defaultQuery) > -1) {
			let searchRegx = /search=(\w|\s|-|\.|#|\+)+/gm;
			let searchQArr = currHash.match(searchRegx);
			if (searchQArr[0].length) {
				initSQ = searchQArr[0].replace(defaultQuery, '');
			}
		}
		return initSQ;
	}

	getPayload() {
		let payload = Object.assign({}, this._defaultPayload);
		const apps = ['intstore_omni', 'esri_store_omni'];

		if (apps.includes(payload.index) || apps.includes(payload.site)) {
			let langCode = window.location.pathname.split('/')[1].substring(0, 2);
			let locale = window.location.pathname.split('/')[1];
			if (window.location.pathname.startsWith('/content') ){
				langCode = 'en';
			}else if ( locale == 'pt-pt' || locale == 'pt-br') {
				payload.lr = locale;
			}
			payload.index = `${langCode}_${payload.index}`;
		}
		payload.q = '';

		let params = new URLSearchParams(window.location.search.slice(1));

		// Sets q value for payload
		if (params.has('q')) {
			let qValue = params.get('q');
			const searchValue = '%2E';
			let periodIndex = qValue.indexOf(searchValue);
			if (periodIndex > -1) {
                qValue = qValue.replace(/%2E/g,'.');
			}

            if(this.app === 'support') {
              const bugRegex = /bug-|BUG-/g;
              qValue = qValue.replace(bugRegex, '');
            }
			payload.q = qValue;
		}

		// Gets filter=value pairs
		let fields = params.toString().split('&');

		// Gets array of filters in url
		let filters = [];
		for (let i = 0; i < fields.length; i++) {
			let filter = fields[i].split('=');
			// Only pushes filters with valid filter keys
			if (this.filterKeys[filter[0]]) {
				filters.push(filter);
			}
		}
		let sortParameter = '';
		if (params.has('s')) {
			sortParameter = params.get('s');
			this.sortData.forEach((sort) => {
				if (sort.label === sortParameter) {
					payload['sort'] = sort.value;
                    this.sortValue = sortParameter;
				}
			});
		}

        if (!params.has("s") && this.sortData && this.sortData.length) {
          payload["sort"] = this.sortData[0].value;
          params.set("s", this.sortData[0].label);
          window.history.replaceState(
            {},
            "",
            window.location.pathname + "?" + params
          );
        }

		// Calculates partialfields for payload and pushes partialsfields to this.activeFilters
		// which is used in other components.  If no filters, delete partialfields from payload.

        let partialfields = !this.appConfig.defaultPayload.partialfields ? '' : this.appConfig.defaultPayload.partialfields;
		this.activeFilters = [];
        let siteFilter = '';

        if (filters.length && filters[0][0] !== '') {
          for (let i = 0; i < filters.length; i++) {
            // This cardtype and deprecation notices or retirement condition is to handle a support s&f edge case
            if(filters[i][0] === 'cardtype' && filters[i][1] !== 'deprecation+notices' && filters[i][1] !== 'productsales') {
              siteFilter = siteFilter + decodeURIComponent(filters[i][1].replace(/\+/g, ' ')) + '|';
            } else if(filters[i][1] === 'deprecation+notices' || filters[i][1] === 'productsales') {
              if(partialfields.length > 0) {
                partialfields = partialfields + '|';
              }
              partialfields = partialfields + filters[i][0] + ':' + decodeURIComponent(filters[i][1].replace(/\+/g, ' '));
              if(siteFilter.indexOf('support_technical_articles') < 0) {
                siteFilter = siteFilter +'support_technical_articles|';
              }
            } else if(filters[i][0] === 'industry') {  // This is to handle industry filter in partners
              partialfields = partialfields + '|page-type:solutions|industry:' + decodeURIComponent(filters[i][1].replace(/\+/g, ' '));
            } else {
              if(partialfields.length > 0) {
                partialfields = partialfields + '|';
              }
              partialfields = partialfields + filters[i][0] + ':' + decodeURIComponent(filters[i][1].replace(/\+/g, ' '));
            }
          }

          if(siteFilter.length > 0) {
            payload['site'] = siteFilter.slice(0, -1);
          } else {
            payload['site'] = this.appConfig.defaultPayload.site;
          }

          if(partialfields.length > 0) {
            payload['partialfields'] = partialfields;
          }

          let activeFilters = filters.reduce((total, filter) => {
            let filterData = filter[0] + ':' + decodeURIComponent(filter[1].replace(/\+/g, ' ')) + '|';
            total = total + filterData;
            return total;
          }, '');
          this.activeFilters = [activeFilters.slice(0, -1)];

        } else {
          if (partialfields.length > 0) {
            payload['partialfields'] = partialfields;
          } else {
            delete payload['partialfields'];
          }
        }


		this.payload = payload;
		return payload;
	}

	handleSearchChange(initQvalue) {
		this.setState({ initQvalue });
	}

	handleSubmit = () => {
		let params = new URLSearchParams(window.location.search.slice(1));

		// Deletes any query, q, if there is query has no value, for
		// cases where q= appear in url
		if (this.state.initQvalue === '' && !params.has('q')) {
			params.delete('q');
		}
		// If there is query change, delete or set q value and delete
		// page, p, to set it back to page 1
		else if (this.state.initQvalue !== (params.get('q') ? params.get('q').replace(/%2E/g, '.') : params.get('q'))) {
			if (this.state.initQvalue === '') {
				params.delete('q');
			} else {
				let qString = this.state.initQvalue.slice();
				let periodIndex = qString.indexOf('.');
				if (periodIndex > -1) {
                    qString = qString.replace(/\./g, '%2E');
				}
				params.set('q', qString);
			}
			// Delete page, p, from url
			params.delete('p');
		}

		window.history.replaceState({}, '', window.location.pathname + '?' + params);
		this.setState({
			activeCallOn: true,
		});

		this.getPayload();
		this.getAppData();
	};

	render() {
		//  Handle errors before API call
		if (this.state.error != null) {
            return (
				<div className="sra search-results-app">
                    <ErrorMessage error={this.props.apiErrorText} />
				</div>
			);
		}

		// Handle no product data from aem
		let resultsData = this.state.resultsData;
		let filtersData = this.state.filtersData;
		if (_.isEmpty(resultsData) || (this.filtersNeeded && _.isEmpty(filtersData))) {
			return (
				<div className="sra search-results-app">
					<div className="grid-container search-results-app-content">
						<Loader />
					</div>
				</div>
			);
		}

		let columnClass = this.filtersNeeded ? 'column-19' : 'column-20';

		let pagination = '';
		if (this.appConfig.pagination && this.appConfig.resultsPerPage) {
			pagination = (
				<div className="grid-container">
					<div className={columnClass + ' pagination-container'}>
						<div className="pagination text-center trailer-1 leader-1">
							<Pagination
								totalResults={this.state.resultsData.totalResults}
								resultsPerPage={this.appConfig.resultsPerPage}
								pageOffset={this.state.resultsData.offset}
								handleSubmit={this.handleSubmit}
                                updatedPageNumber = {this.localizationLabels.updatedResultsText}
							/>
						</div>
					</div>
				</div>
			);
		}

		let filters = '';
		if (this.filtersNeeded) {
			filters = (
				<Filters
					labels={this.localizationLabels}
					activeFilters={this.activeFilters}
					isFilterCatOpen={this.props.isFilterCatOpen}
					filtersData={filtersData}
					handleSubmit={this.handleSubmit}
				/>
			);
		}
		return (
			<div className="sra search-results-app">
				<div className="grid-container search-results-app-content">
					{filters}

					<div className={columnClass + ' search-results-app-results'}>
						<SingleBarSearch
							labels={this.localizationLabels}
							initQvalue={this.state.initQvalue}
							handleSearchChange={this.handleSearchChange}
							handleSubmit={this.handleSubmit}
						/>
						<SearchOptions
							sortData={this.sortData}
                            sortValue={this.sortValue}
							labels={this.localizationLabels}
							msgNoResults={this.resultsMsg}
							results={resultsData.totalResults}
							activeFilters={this.activeFilters}
							handleSubmit={this.handleSubmit}
						/>
						<Results
							activateLoader={this.state.activeCallOn}
							featuredList={this.featuredList}
							cardsData={resultsData}
							app={this.app}
							dataApiPath={this.dataApiPath}
							multipleLocationsText={this.props.multloctext}
							viewJobText={this.props.viewjobtext}
							noResultsText={this.props.noResults}
							apiErrorText={this.props.apiErrorText}
							multiplePresentersText={this.props.multprestext}
							sessionBtnText={this.props.sessionbtntext}
							labels={this.localizationLabels}
							viewArticleBtnText={this.props.viewarticletext}
							viewTermBtnText={this.props.viewtermtext}
							viewBugBtnText={this.props.viewbugtext}
							viewTechPaperBtnText={this.props.viewtechpapertext}
							viewPatchUpdateBtnText={this.props.viewpatchupdatetext}
							viewProductBtnText={this.props.viewproducttext}
							viewBlogBtnText={this.props.viewblogtext}
							viewCommunityBtnText={this.props.viewcommunitytext}
						/>
					</div>
				</div>
				{pagination}
			</div>
		);

	}
}

MapTo('esri-sites/components/content/search-results')(SearchResultsApp);
export default SearchResultsApp;

// v.1
