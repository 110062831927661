class OmniNormalizer {
	constructor(rawResults) {
		this.data = rawResults.data;
	}

	normalizedData() {
		try {
			if (!this.data.search) {
				throw new Error('[ERROR] API issue, check search api results back!!!');
			}
		} catch (e) {
			return { error: e };
		}

		let rawResults = this.data.search;
		let searchResults = {
			totalResults: rawResults.count,
			resultCards: [],
		};
		if (searchResults.totalResults == 0) {
			return searchResults;
		}
		searchResults.resultCards = rawResults.hits.map((rawResult) => {
			let card = {};
			let result = rawResult.doc;
			card.title = result.title || '';
			card.content = result.content || '';
			card.description = result.description || '';
			card.pricePrefix = result.metaFields.pricePrefix || '';
			card.productDetailLabel = result.metaFields.productDetailLabel || '';
			card.linkcta1 = result.metaFields.linkcta1 || '';
			card.linkcta2 = result.metaFields.linkcta2 || '';
			card.platform = result.metaFields.platform;
			card.discountText = result.metaFields.discountText || '';
			card.productDetailLink = result.metaFields.productDetailLink || '';
			card.price = result.metaFields.price || '';
			card.productTag = result.metaFields.productTag || '';
			card.labelcta1 = result.metaFields.labelcta1 || '';
			card.labelcta2 = result.metaFields.labelcta2 || '';
			card.productType = result.metaFields.productType || '';
			card.themeColor = result.metaFields.themeColor || '';
			card.productIcon = result.metaFields.productIcon || '';
			card.isCollection = typeof result.metaFields.isCollection == 'undefined' || result.metaFields.isCollection == 'false' ? false : true;
			return card;
		});
		return searchResults;
	}
}

export default OmniNormalizer;
