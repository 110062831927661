import React from "react";

const CalciteCheckbox = ({ checked, scale, status, onChange }) => {
  const calciteCheckboxRef = React.useRef(null);
  React.useEffect(() => {
    if (onChange) {
      calciteCheckboxRef.current.addEventListener("calciteCheckboxChange", onChange);
    }
    return () => {
      if (onChange) {
        calciteCheckboxRef.current.removeEventListener("calciteCheckboxChange", onChange);
      }
    };
  }, []);
  return (
    <calcite-checkbox
      checked={checked || undefined}
      scale={scale || undefined}
      status={status || undefined}
      onChange={onChange || undefined}
      ref={calciteCheckboxRef}
    ></calcite-checkbox>
  );
};

export default CalciteCheckbox;
