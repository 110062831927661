import React from 'react';

export default function MarketplaceCard(props) {
	let card = props.cardinfo;
	let description = card.description;
	let rating = card.rating * 20;
	let isFeature;
	if (card.feature === 'true') {
		isFeature = card.isFeature;
	}
	let showrating;
	let pricingModel;
	if (card.pricingModel === 'FREE') {
		pricingModel = 'FREE';
	} else if (card.pricingModel[0] === 'TRIAL' || card.pricingModel[1] === 'TRIAL') {
		pricingModel = 'TRIAL';
	}
	if (card.rating > 0) {
		showrating = true;
	}

	if (description && description.length > 150) {
		description = description.slice(0, 147) + '...';
	}

	function addDefaultSrc(ev) {
		if (card.productType == 'Professional Services') {
			ev.target.src = 'https://www.esri.com/content/dam/esrisites/en-us/common/icons/meridian-/partners-48.svg';
		} else {
			ev.target.src = 'https://www.esri.com/content/dam/esrisites/en-us/common/icons/meridian-color-baked-white/apps-48.svg';
		}
	}

	return (
		<div class="block card trailer-half" data-gs-trigger="true" data-gs-delay="0.2" data-gs-anim="fade-up" data-gs-speed="1.0">
			<div class="cards-simple lighttheme">
				<a href={card.link}>
					<div class="card-content">
						<div class="card-content-header">
							<div class="icon-container">
								<div class="icon-image">
									<img class="svg" onError={addDefaultSrc} src={card.icon} alt="" />
								</div>
								{isFeature && (
									<div class="icon-feature">
										<p>Featured</p>
									</div>
								)}
							</div>
							<h2 class="card-title avenir-demi">{card.title}</h2>
							<h3 class="card-subheader avenir-light">By {card.vendor}</h3>
							<p className="card-listingtype avenir-demi trailer-0">{card.productType}</p>
							<div class="provider-level"></div>
						</div>
						<div class="card-text">
							<div dangerouslySetInnerHTML={{ __html: description }} />
						</div>
					</div>
					<div class="review-section-content-star">
						{showrating && (
							<div class="star-ratings-wrapper x-small leader-half trailer-1">
								<div class="star-ratings-blank x-small"></div>
								<div class="star-ratings-filled x-small" id="starfilled" style={{ width: `${rating}%` }}></div>
							</div>
						)}
						<div class="freeTag">
							<p class="freeTag-text">{pricingModel}</p>
						</div>
					</div>
				</a>
			</div>
		</div>
	);
}
